import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IonButton } from '@ionic/react';

const BillingButton = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const billing = async () => {

    const accessToken = await getAccessTokenSilently();

    window.analytics.track('View', {

      page: "Stripe Billing",
      name: user?.["name"],
      email: user?.["email"],
      oauth: user?.["sub"]

    })

    const response = await fetch("https://meom.guava.cloud/stripe/?service=billing&token="+ accessToken)
         .then(res => res.json())
         .then(
           (result) => {

             window.location = result.url;

           },
           (error) => { console.log(error); }
     )
  }

  if (user?.["https://meom.guava.cloud/stripe_id"]?.startsWith("cus")) {

    return (
        <IonButton onClick={() => billing()}>
          Manage subscription and billing
        </IonButton>
    );
  }
  else {

    return (
        <IonButton disabled={ true }>
          Manage subscription and billing
        </IonButton>
    );
  }
};

export default BillingButton;
