import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Ideas from './pages/Ideas';
import Content from './pages/Content';
import Report from './pages/Report';
import Account from './pages/Account';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react';
import { calendar, personCircle, map, informationCircle } from 'ionicons/icons';

setupIonicReact({
  animated: false
});


(window as any).baseUrl = "http://localhost:5000";


const App: React.FC = () => (

  <IonApp>

    <IonReactRouter>
      <IonRouterOutlet>

        <Redirect from="/" to="/report" exact={true} />
        <Route path="/signup" component={Account} exact={true} />
        <Route path="/account" component={Account} exact={true} />

        <Route path="/report" component={Report} exact={true} />
        <Route path="/ideas" component={Ideas} exact={true} />
        <Route path="/content" component={Content} exact={true} />

      </IonRouterOutlet>
    </IonReactRouter>

  </IonApp>
);

export default App;
