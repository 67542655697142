import AuthWrapper from '../AuthWrapper';
import { useState, useEffect } from 'react';
import {
  IonContent,
  IonCol
} from '@ionic/react';
const ContentContent: React.FC = () => {

  const [selectedDomain, setSelectedDomain] = useState(() => {
    return localStorage.getItem('selectedDomain') || 'www.meom.fi';
  });

  useEffect(() => {

    const updateDomain = () => {
      setSelectedDomain(localStorage.getItem('selectedDomain') || 'www.meom.fi');
    };

    window.addEventListener('domainChange', updateDomain);

    return () => {
      // Cleanup the event listener when component unmounts
      window.removeEventListener('domainChange', updateDomain);
    };
  }, []);  

  return (
    <div>
      <iframe name="generator" className="fullscreen border_bottom" height="700" src={`https://europe-west3-ai-analytics-387514.cloudfunctions.net/ai-analytics-prompt?ui=1&type=content&domain=${selectedDomain}`}></iframe>
    </div>
  );
};

const Content: React.FC = () => {
  return <AuthWrapper ContentComponent={ContentContent} />;
};

export default Content;
