import LogoutButton from '../components/LogoutButton';
import SubscribeButton from '../components/SubscribeButton';
import BillingButton from '../components/BillingButton';
import BigQueryButton from '../components/BigQueryButton';
import Menu from '../components/Menu';
import { NavLink } from 'react-router-dom';
import { card, lockClosedOutline, lockOpenOutline, server, person } from 'ionicons/icons'
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonCol,
  IonRow,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonIcon,
  IonLabel,
  IonChip,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonImg,
  IonInput,
  useIonViewWillEnter
} from '@ionic/react';

import '../theme/pages.css';

const Account: React.FC = () => {

  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {

    if (!isLoading && isAuthenticated && user != null && (window.location).toString().endsWith('account.html')) {

      if (window.userIdentified == false) {

          window.analytics.identify(user?.["sub"], {
            name: user?.["name"],
            email: user?.["email"]
          });

          window.userIdentified = true;
      }

      window.analytics.track('View', {

        page: "Account",
        name: user?.["name"],
        email: user?.["email"],
        oauth: user?.["sub"]

      });
    }

  });

  function StripeChip() {

    if (user?.["https://meom.guava.cloud/stripe_id"]?.startsWith("cus")) {

      return <IonChip><IonIcon icon={card} color="dark" className="datasetActiveChip"></IonIcon><IonLabel>Active subscription</IonLabel></IonChip>;
    }
    else {

      return <IonChip><IonIcon icon={card} color="dark" className="datasetActiveChip"></IonIcon><IonLabel>No subscriptions</IonLabel></IonChip>;
    }
  }

  if (isLoading) {

    return (
      <IonPage id="account-page">
        <IonContent fullscreen>
        </IonContent>
      </IonPage>
    );
  }
  else if (isAuthenticated) {

    return (

      <IonPage id="account-page">

        <IonContent fullscreen>

          <IonRow>
            <IonCol size="3" class="menu">
              <Menu />
            </IonCol>
            <IonCol class="content">

              <h1>Account</h1>
              <div className="description">
                Please contact MEOM if you need to update or cancel your account.
              </div>

              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="dataset">Your account</IonCardTitle>
                  <IonChip><IonIcon icon={person} color="dark" className="datasetActiveChip"></IonIcon><IonLabel>{user?.email}</IonLabel></IonChip>
                </IonCardHeader>
              </IonCard>

              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="dataset">Billing details and tax receipts</IonCardTitle>
                  <StripeChip />
                </IonCardHeader>
                <IonCardContent>
                  <BillingButton/>
                </IonCardContent>
              </IonCard>

            </IonCol>
          </IonRow>

        </IonContent>
      </IonPage>
    );
  }
  else {

    if (window.location.pathname.includes("signup")) {

       loginWithRedirect({ screen_hint: 'signup' })
    }
    else {

       loginWithRedirect()
    }

    return (
      <IonPage id="account-page">
        <IonContent fullscreen>
        </IonContent>
      </IonPage>
    );
  }
};

export default Account;
