import Menu from './components/Menu';
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from 'react';
import {
  IonContent,
  IonCol,
  IonRow,
  IonPage
} from '@ionic/react';
import { NavLink } from 'react-router-dom';
const AuthWrapper: React.FC<{ ContentComponent: React.ComponentType }> = ({ ContentComponent }) => {

  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    if (!isLoading && isAuthenticated && user != null) {

      /*
      if (window.userIdentified == false) {

          window.analytics.identify(user?.["sub"], {
            name: user?.["name"],
            email: user?.["email"]
          });

          window.userIdentified = true;
      }

      window.analytics.track('View', {

        page: "Analytics",
        name: user?.["name"],
        email: user?.["email"],
        oauth: user?.["sub"]

      });
      */

    } 
  });

  if (isLoading) {

    return (
      <IonPage id="wrapper-page">
        <IonContent fullscreen>
        </IonContent>
      </IonPage>
    );
  }
  else if (isAuthenticated) {

    return (
      <IonPage id="wrapper-page">

        <IonContent fullscreen>

          <IonRow>
            <IonCol size="3">
              <Menu />
            </IonCol>
            <IonCol size="9" class="content">

                <ContentComponent />

            </IonCol>
          </IonRow>

        </IonContent>
      </IonPage>
    );
  }
  else {

    if (window.location.pathname.includes("signup")) {

       loginWithRedirect({ screen_hint: 'signup' })
    }
    else {

       loginWithRedirect()
    }

    return (
      <IonPage id="wrapper-page">
        <IonContent fullscreen>
        </IonContent>
      </IonPage>
    );
  }
};

export default AuthWrapper;
