import AuthWrapper from '../AuthWrapper';
import { useState, useEffect } from 'react';
import {
  IonContent,
  IonCol
} from '@ionic/react';
const ReportContent: React.FC = () => {

  const [selectedDomain, setSelectedDomain] = useState(() => {
    return localStorage.getItem('selectedDomain') || 'www.meom.fi';
  });

  const [reportUrl, setReportUrl] = useState('');

  useEffect(() => {

    const updateDomain = () => {
      setSelectedDomain(localStorage.getItem('selectedDomain') || 'www.meom.fi');              
    };

    window.addEventListener('domainChange', updateDomain);

    return () => {
      // Cleanup the event listener when component unmounts
      window.removeEventListener('domainChange', updateDomain);
    };
  }, []);  

  useEffect(() => {
    // Update reportUrl based on selectedDomain
    if (selectedDomain === 'www.meom.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/12900244-42a6-4d14-949d-3462a453500a/page/ZTfTD');
    } else if (selectedDomain === 'oi.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/a0c4e9f9-7362-4c65-8967-744a1d96c11e/page/ZTfTD');
    } else if (selectedDomain === 'laurea.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/1e4b8ecb-2c02-45fa-b940-4c4158fefaa4/page/ZTfTD');
    } else if (selectedDomain === 'www.valteri.fi') {
      setReportUrl('https://lookerstudio.google.com/embed/reporting/140c8e90-eadc-4828-8da3-93e384ae1084/page/ZTfTD');
    }

  }, [selectedDomain]);

  return (
    <div>

      <iframe className="fullscreen" height="700" src={`${reportUrl}`}></iframe>

    </div>
  );
};

const Report: React.FC = () => {
  return <AuthWrapper ContentComponent={ReportContent} />;
};

export default Report;
