import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { IonItem, IonList, IonSelect, IonSelectOption, IonButton, IonIcon, IonImg, IonRouterLink, useIonRouter } from '@ionic/react';
import { barChartOutline, bulbOutline, createOutline, logOutOutline, personOutline } from 'ionicons/icons'
import './Menu.css';
import { NavLink } from 'react-router-dom';
const Menu = ({ history }: any) => {

  const { user, logout } = useAuth0();
  const [selectedDomain, setSelectedDomain] = useState(() => {
    return localStorage.getItem('selectedDomain') || 'www.meom.fi';
  });

  const handleDomainChange = (e: any) => {
    const domain = e.detail.value;
    setSelectedDomain(domain);
    localStorage.setItem('selectedDomain', domain);
    window.dispatchEvent(new Event('domainChange'));
  };

  return (
    <IonList className="bg-transparent">
      <IonItem color="none" lines="none"><IonImg src="/assets/icon/favicon.png" className="logo" /></IonItem>

        <IonSelect className="product" label="Domain" labelPlacement="floating" interface="popover" fill="outline" 
                   value={selectedDomain} 
                   onIonChange={handleDomainChange}>
          <IonSelectOption className="product product-option" value="www.meom.fi">www.meom.fi</IonSelectOption>
          <IonSelectOption className="product product-option" value="oi.fi">oi.fi</IonSelectOption>
          <IonSelectOption className="product product-option" value="laurea.fi">laurea.fi</IonSelectOption>
          <IonSelectOption className="product product-option" value="www.valteri.fi">www.valteri.fi</IonSelectOption>
        </IonSelect>

      <NavLink to="/report" className="menu"><IonItem color="none" lines="none"><IonIcon icon={barChartOutline} size="small" className="menu" /><span className="hide-mobile" >Gain insights</span></IonItem></NavLink>
      <NavLink to="/ideas" className="menu"><IonItem color="none" lines="none"><IonIcon icon={bulbOutline} size="small" className="menu" /><span className="hide-mobile" >Generate ideas</span></IonItem></NavLink>
      <NavLink to="/content" className="menu"><IonItem color="none" lines="none"><IonIcon icon={createOutline} size="small" className="menu" /><span className="hide-mobile" >Create content</span></IonItem></NavLink>
      <IonItem color="none" lines="none"></IonItem>
      <NavLink to="/account" className="menu"><IonItem color="none" lines="none"><IonIcon icon={personOutline} size="small" className="menu" /><span className="hide-mobile" >Account</span></IonItem></NavLink>
      <IonItem color="none" lines="none" className="pointer" onClick={() => logout()}><IonIcon icon={logOutOutline} size="small" className="menu" /><span className="hide-mobile" >Logout</span></IonItem>
    </IonList>
  );

};

export default Menu;
